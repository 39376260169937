import React, { useState } from "react"

export const PreviewContext = React.createContext()

const PreviewProvider = ({ children }) => {
  const [previewData, setPreviewData] = useState(null)

  const contextValues = {
    previewData,
    setPreviewData,
  }

  return (
    <PreviewContext.Provider value={contextValues}>
      {children}
    </PreviewContext.Provider>
  )
}

export default PreviewProvider
