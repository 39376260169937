const pageData = `
query($id: ID! $databaseId: ID!) {
  page(id:  $id ) {
    title
    content
    uri
    seo {
      title
      metaDesc
    }
     headerSettings {
        customPageTitle
        headerText
        videoModal
        logoArea {
          logo {
            sourceUrl
          }
          text
        }
      }
    avContactData {
      country
      cdDetails {
        headOffice
        address
        emailEnquiry
        mapImage {
          sourceUrl
          srcSet
        }
        phone
        
      }
      relatedPosts {
        items {
          ... on Post {
            title
            uri
            featuredImage {
              node {
                sourceUrl
                srcSet
              }
            }
            categories {
              nodes {
                slug
                name
              }
            }
          }
        }
      }
      ctaPage {
        ... on Page {
          title
          excerpt
          uri
        }
      }
    }
    ancestors {
      nodes {
        ... on Page {
          depth
          title
          uri
        }
      }
    }
    featuredImage {
      node {
        sourceUrl
        srcSet
        mediaDetails {
          height
          width
        }
      }
    }
    pageSettings {
      relatedPages {
        ... on Page {
          title
          excerpt
          uri
          featuredImage {
            node {
              sourceUrl
              srcSet
              mediaDetails {
                height
                width
              }
            }
          }
        }
      }
    }
    
  }

  allWpPage: pages(where: {parent: $databaseId, orderby: {field: MENU_ORDER, order: ASC}}) {
    nodes {
      title
      content
      uri
      excerpt
      featuredImage {
        node {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
      }
    }
  }
  
}
`

const homeData = `
query ($id: ID!) {
  page(id: $id) {
    title
    uri
    featuredImage {
      node {
        sourceUrl
        srcSet
      }
    }
    seo {
      title
      metaDesc
    }
    
    headerSettings {
      customPageTitle
      headerText
      headerPage {
        ... on Page {
          title
          uri
        }
      }
    }
    avLocations {
      locHeading
      locDescription
      locItems {
        label
        image {
          sourceUrl
          srcSet
        }
        linkPage {
          ... on Page {
            uri
          }
        }
      } 
    }
    avStatistics {
      statExcerpt
      statHeading
      statItems {
        statistic
        description
      }
    }
    avSolutionTiles {
      stExcerpt
      stHeading
      stViewAll {
        ... on Page {
          uri
        }
      }
      stItems {
        content
        icon
        page {
          ... on Page {
            title
            uri
          }
        }
      }
    }

    avTestimonials {
      tsItems {
        author
        image {
          sourceUrl
          srcSet
        }
        testimonial
      }
    }

    avCarousel {
      fcHeading
      fcItems {
        heading
        excerpt
        page {
          ... on Page {
            title
            uri
          }
        }
        image {
          sourceUrl
          srcSet
        }
      }
    }
    avFeatureTiles {
      ftHeading
      ftMessage
      ftTiles {
        content
        icon
        heading
      }
      ftShowVideo
      ftVideo {
        url
        thumbnailImage {
          sourceUrl
        }
      }
    }
  }
}
`
const postData = `
query($id: ID!) {
  post(id:  $id ) {
    title
    uri
    content
    headerSettings {
      customPageTitle
    }
    seo {
      title
      metaDesc
    }
    featuredImage {
      node {
        sourceUrl
        srcSet
        mediaDetails {
          height
          width
        }
      }
    }
    categories {
      nodes {
        name
        slug
      }
    }
  }
}
`

module.exports = {
  pageData,
  postData,
  homeData,
}
